import React from 'react';
import { useEffect } from 'react';

import { Link } from "react-router-dom";


const Portfolio = () =>{
    useEffect(() => {
        const script = document.createElement('script');
        const script2 = document.createElement('script');
      
        script.src = "/assets/js/portfolio.js";
        script.async = true;
      
        script2.src = "/assets/js/main.js";
        script2.async = true;
      
        document.body.appendChild(script);
        document.body.appendChild(script2);
      
        return () => {
          document.body.removeChild(script);
          document.body.removeChild(script2);
        }
  }, []);
  return (
    <div>
      <div className="client-logo first-section">
          <div className="container">
              <div className="row">
                  <div className="col-12">
                      <div className="section-title">
                          <h3 className="wow zoomIn" data-wow-delay=".2s">Clients</h3>
                          <h2 className="wow fadeInUp" data-wow-delay=".4s">Our Clients</h2>
                          <p className="wow fadeInUp" data-wow-delay=".6s">It has been trusted by several government and
                              private companies</p>
                      </div>
                  </div>
              </div>
              <div className="row align-items-center justify-content-md-center client-logo-carousel">
                  <div className="single-logo">
                      <img src="assets/images/client/syariahrooms.png" alt="Syariahrooms"/>
                  </div>
                  <div className="single-logo">
                      <img src="assets/images/client/kelola.png" alt="Kelola"/>
                  </div>
                  <div className="single-logo">
                      <img src="assets/images/client/bank-syariah-indonesia.png" alt="Bank Syariah Indonesia"/>
                  </div>
                  <div className="single-logo">
                      <img src="assets/images/client/eka-akar-jati.png" alt="Eka Akar Jati"/>
                  </div>
                  <div className="single-logo">
                      <img src="assets/images/client/ide-learning.png" alt="IDE Learning"/>
                  </div>
                  <div className="single-logo">
                      <img src="assets/images/client/aset.png" alt="Aset.id"/>
                  </div>
                  <div className="single-logo">
                      <img src="assets/images/client/halal-export-indonesia.png" alt="Halal Export Indonesia"/>
                  </div>
                  <div className="single-logo">
                      <img src="assets/images/client/hoola-drinks.png" alt="Hoola Drinks"/>
                  </div>
              </div>
          </div>
      </div>

      <section className="portfolio-section section">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <h3 className="wow zoomIn" data-wow-delay=".2s">Portfolio</h3>
                            <h2 className="wow fadeInUp" data-wow-delay=".4s">Our Last Projects</h2>
                            <p className="wow fadeInUp" data-wow-delay=".6s">We have helped complete various projects from various types of products. Come check out our portfolio!</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="portfolio-btn-wrapper wow fadeInUp" data-wow-delay=".4s">
                            <button className="portfolio-btn active" data-filter="*">Show All</button>
                            <button className="portfolio-btn" data-filter=".webapp">Web App</button>
                            <button className="portfolio-btn" data-filter=".mobileapp">Mobile App</button>
                        </div>
                    </div>
                </div>
                <div className="row grid ">
                    <div className="col-lg-3 col-md-6 col-12 p-2 grid-item webapp">
                        <div className="portfolio-item-wrapper">
                            <div className="portfolio-img">
                                <img src="assets/images/portfolio/syariahrooms.jpg" alt="Syariahrooms"/>
                            </div>
                            <div className="portfolio-overlay">
                                <div className="pf-content">
                                    <span className="category">Web App</span>
                                    <h4>Syariahrooms</h4>
                                    <p>Syariahrooms is a sharia lodging booking website that has spread in several cities</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12 p-2 grid-item webapp">
                        <div className="portfolio-item-wrapper">
                            <div className="portfolio-img">
                                <img src="assets/images/portfolio/kelola.jpg" alt="Kelola.net"/>
                            </div>
                            <div className="portfolio-overlay">
                                <div className="pf-content">
                                    <span className="category">Web App</span>
                                    <h4>Kelola.net</h4>
                                    <p>Kelola.net is a practical website that is equipped with advanced features to help you develop your clinic</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12 p-2 grid-item webapp">
                        <div className="portfolio-item-wrapper">
                            <div className="portfolio-img">
                                <img src="assets/images/portfolio/siber.jpg" alt="Sistemberkah"/>
                            </div>
                            <div className="portfolio-overlay">
                                <div className="pf-content">
                                    <span className="category">Web App</span>
                                    <h4>Sistemberkah</h4>
                                    <p>Sistemberkah is a fast approval (SLA Real Time) used by Bank Syariah Indonesia (BSI) in approving financing applications</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-lg-3 col-md-6 col-12 p-2 grid-item webapp">
                        <div className="portfolio-item-wrapper">
                            <div className="portfolio-img">
                                <img src="assets/images/portfolio/siber-telemarketing.jpg" alt="Sistemberkah Telemarketing"/>
                            </div>
                            <div className="portfolio-overlay">
                                <div className="pf-content">
                                    <span className="category">Web App</span>
                                    <h4>Sistemberkah - Telemarketing</h4>
                                    <p>Sistemberkah - Telemarketing is a marketing system by offering products or services to customers by telephone</p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="col-lg-3 col-md-6 col-12 p-2 grid-item webapp">
                        <div className="portfolio-item-wrapper">
                            <div className="portfolio-img">
                                <img src="assets/images/portfolio/ekaakarjati.jpg" alt="Eka Akar Jati"/>
                            </div>
                            <div className="portfolio-overlay">
                                <div className="pf-content">
                                    <span className="category">Web App</span>
                                    <h4>Ekaakarjati</h4>
                                    <p>Ekaakarjati is a company profile website as the identity of PT Eka Akar Jati</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12 p-2 grid-item webapp">
                        <div className="portfolio-item-wrapper">
                            <div className="portfolio-img">
                                <img src="assets/images/portfolio/karir-ekaakarjati.jpg" alt="Karir Ekaakarjati"/>
                            </div>
                            <div className="portfolio-overlay">
                                <div className="pf-content">
                                    <span className="category">Web App</span>
                                    <h4>Ekaakarjati - Karir</h4>
                                    <p>Ekaakarjati is a recruitment website provided by PT Eka Akar Jati which features job application, automatic selection and online test features.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12 p-2 grid-item webapp">
                        <div className="portfolio-item-wrapper">
                            <div className="portfolio-img">
                                <img src="assets/images/portfolio/idelearning.jpg" alt="IDE Learning"/>
                            </div>
                            <div className="portfolio-overlay">
                                <div className="pf-content">
                                    <span className="category">Web App</span>
                                    <h4>IDE Learning</h4>
                                    <p>IDE Leraning is a company profile website as the identity of 
                                        Indonesia's Leading Digital Soft Skill Learning </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12 p-2 grid-item webapp">
                        <div className="portfolio-item-wrapper">
                            <div className="portfolio-img">
                                <img src="assets/images/portfolio/HEI-Web.jpg" alt="Web Halal Export Indonesia"/>
                            </div>
                            <div className="portfolio-overlay">
                                <div className="pf-content">
                                    <span className="category">Web App</span>
                                    <h4>HEI - Web</h4>
                                    <p>HEI is an export marketplace website that provides export of Indonesian products. Equipped with merchant and e-learning features to learn about export and import</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12 p-2 grid-item mobileapp">
                        <div className="portfolio-item-wrapper">
                            <div className="portfolio-img">
                                <img src="assets/images/portfolio/HEI-Apps.jpg" alt="Apps Halal Export Indonesia"/>
                            </div>
                            <div className="portfolio-overlay">
                                <div className="pf-content">
                                    <span className="category">Mobile App</span>
                                    <h4>HEI - Apps</h4>
                                    <p>HEI is an export marketplace apps (Android/iOS) that provides export of Indonesian products. Equipped with merchant and e-learning features to learn about export and import</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12 p-2 grid-item mobileapp">
                        <div className="portfolio-item-wrapper">
                            <div className="portfolio-img">
                                <img src="assets/images/portfolio/aset.jpg" alt="Sistemberkah Telemarketing"/>
                            </div>
                            <div className="portfolio-overlay">
                                <div className="pf-content">
                                    <span className="category">Mobile App</span>
                                    <h4>Aset.id</h4>
                                    <p>Aset.id is the largest mobile-based property marketplace application (Android and iOS) that provides property sales and purchases.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12 p-2 grid-item webapp">
                        <div className="portfolio-item-wrapper">
                            <div className="portfolio-img">
                                <img src="assets/images/portfolio/hoola.jpg" alt="IDE Learning"/>
                            </div>
                            <div className="portfolio-overlay">
                                <div className="pf-content">
                                    <span className="category">Web App</span>
                                    <h4>Hoola.id</h4>
                                    <p>Hoola.id is a company profile website as the identity of 
                                        Hoola Drinks </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </section>

      <section className="call-action">
          <div className="container">
              <div className="row align-items-center">
                  <div className="col-lg-8 col-12">
                      <div className="text">
                          <h2>Consult your problem with us, <span>and we will provide the best solution for you</span>
                          </h2>
                      </div>
                  </div>
                  <div className="col-lg-4 col-12">
                      <div className="button">
                          <Link to="/contact" className="btn">Contact Us
                          </Link>
                      </div>
                  </div>
              </div>
          </div>
      </section>
    </div>
  );
}
export default Portfolio;